<template>
  <div class="card p-3">
    <div class="flex align-items-center mb-2 justify-content-between">
      <h2 class="text-xl font-semibold">Nghe nhiều</h2>
      <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <a href="javascript:void(0)"
          v-on:click="period = 'week'"
          :class="{'bg-gray-200': period === 'week'}"
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          Tuần
        </a>
        <a href="javascript:void(0)"
          v-on:click="period = 'month'"
          :class="{'bg-gray-200': period === 'month'}"
          class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          Tháng
        </a>
        <a href="javascript:void(0)"
          v-on:click="period = 'year'"
          :class="{'bg-gray-200': period === 'year'}"
          class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          Năm
        </a>
        <a href="javascript:void(0)"
          v-on:click="period = ''"
          :class="{'bg-gray-200': period === ''}"
          class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          Tất cả
        </a>
      </nav>
    </div>

    <nav class="responsive-nav -mb-0.5 lg:pl-2">
      <div class="flex justify-between flex-col-reverse lg:flex-row">
        <nav class="responsive-nav is_ligh -mb-0.5 border-transparent">
          <ul>
            <li :class="{'active': type === 'song'}"><a href="javascript:void(0)" v-on:click="type = 'song'">Tân nhạc</a></li>
            <li :class="{'active': type === 'folk'}"><a href="javascript:void(0)" v-on:click="type = 'folk'">Dân ca</a></li>
            <li :class="{'active': type === 'instrumental'}"><a href="javascript:void(0)" v-on:click="type = 'instrumental'">Khí nhạc</a></li>
            <li v-if="showKaraoke === true" :class="{'active': type === 'karaoke'}"><a href="javascript:void(0)" v-on:click="type = 'karaoke'">Thành viên hát</a></li>
            <li :class="{'active': type === 'poem'}"><a href="javascript:void(0)" v-on:click="type = 'poem'">Tiếng thơ</a></li>
          </ul>
        </nav>
      </div>
    </nav>

    <table class="mt-3 w-full">
      <tbody>
        <statistic-item :showViews="showViews" :index="(statistics.paginatorInfo.currentPage-1) * 5 + index + 1" :total="item.total" :prefix="getPrefixFromSongType(item.object_type)" :song="item.object" v-for="(item, index) in statistics.data"
                          :key="'statisticItem' + index"/>
      </tbody>
    </table>

    <div class="mt-4" v-if="showPaging === true">
      <v-pagination
          class="justify-content-end"
          v-if="statistics.paginatorInfo.lastPage > 1"
          v-model="statistics.paginatorInfo.currentPage"
          :pages="statistics.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadStatistic"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {getPrefixFromSongType} from "../../core/services/utils.service";
import StatisticItem from "./StatisticItem";

export default {
  name: "StatisticListen",
  components: {StatisticItem},
  props: {
    showPaging: Boolean,
    showKaraoke: Boolean,
    showViews: Boolean
  },
  data() {
    return {
      statistics: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: null,
          total: 0
        }
      },
      period: "week",
      type: "song"
    }
  },
  methods: {
    loadStatistic() {
      let query = `query($first: Int, $page: Int, $period: String, $type: String!) {
        statisticListen(first: $first, page: $page, period: $period, type: $type) {
          data {
            object_id
            object_type
            total
            object {
              id
              __typename
              ... on Song {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Folk {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Instrumental {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Karaoke {
                id
                title
                slug
                thumbnail {
                  url
                }
                users(first: 10) {
                  data {
                    username
                    id
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Poem {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
            }
          }
          paginatorInfo {
            currentPage
            total
            lastPage
          }
        }
      }
      `;

      ApiService.graphql(query, {first: 5, period: this.period, type: this.type, page: this.statistics.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.statisticListen && data.data.statisticListen.data) {
              this.statistics = data.data.statisticListen;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    getPrefixFromSongType(x) {
      return getPrefixFromSongType(x.toLowerCase());
    },
  },
  mounted() {
    this.loadStatistic();
  },
  watch: {
    type: {
      handler() {
        this.statistics.paginatorInfo.currentPage = 1;
        this.loadStatistic();
      }
    },
    period: {
      handler() {
        this.statistics.paginatorInfo.currentPage = 1;
        this.loadStatistic();
      }
    }
  }
}
</script>
