<template>
  <div>
    <div class="flex justify-between flex-col-reverse lg:flex-row">
      <nav class="responsive-nav is_ligh -mb-0.5 border-transparent">
        <ul uk-switcher="connect: #latestSongs-tab; animation: uk-animation-fade">
          <li><a href="#">Tân nhạc <span class="today" v-if="songTodayCount">{{ songTodayCount }}</span></a></li>
          <li><a href="#">Dân ca <span class="today" v-if="folkTodayCount">{{ folkTodayCount }}</span></a></li>
          <li><a href="#">Khí nhạc <span class="today" v-if="instrumentalTodayCount">{{ instrumentalTodayCount }}</span></a></li>
          <li><a href="#">Th.viên hát <span class="today" v-if="karaokeTodayCount">{{ karaokeTodayCount }}</span></a></li>
          <li><a href="#">Tiếng thơ <span class="today" v-if="poemTodayCount">{{ poemTodayCount }}</span></a></li>
        </ul>
      </nav>
    </div>

    <div class="uk-switcher" id="latestSongs-tab">
      <div>
        <div class="mt-4" v-if="songs.data.length">
          <song-item-upload v-for="song in songs.data" :song="song" prefix="bai-hat" :key="'song' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-end"
              v-if="songs.paginatorInfo.lastPage > 1"
              v-model="songs.paginatorInfo.currentPage"
              :pages="songs.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadSongs"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="folks.data.length">
          <song-item-upload v-for="song in folks.data" :song="song" prefix="dan-ca" :key="'folk' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-end"
              v-if="folks.paginatorInfo.lastPage > 1"
              v-model="folks.paginatorInfo.currentPage"
              :pages="folks.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadFolks"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="instrumentals.data.length">
          <song-item-upload v-for="song in instrumentals.data" :song="song" prefix="khi-nhac"
                            :key="'instrumental' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-end"
              v-if="instrumentals.paginatorInfo.lastPage > 1"
              v-model="instrumentals.paginatorInfo.currentPage"
              :pages="instrumentals.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadInstrumentals"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="karaokes.data.length">
          <song-item-upload v-for="song in karaokes.data" :song="song" prefix="thanh-vien-hat"
                            :key="'karaoke' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-end"
              v-if="karaokes.paginatorInfo.lastPage > 1"
              v-model="karaokes.paginatorInfo.currentPage"
              :pages="karaokes.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadKaraokes"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="poems.data.length">
          <song-item-upload v-for="song in poems.data" :song="song" prefix="tieng-tho" :key="'poem' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-end"
              v-if="poems.paginatorInfo.lastPage > 1"
              v-model="poems.paginatorInfo.currentPage"
              :pages="poems.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadPoems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import SongItemUpload from "./SongItemUpload";

export default {
  name: "LatestSongBox",
  components: {
    SongItemUpload
  },
  data() {
    return {
      songs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      folks: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      instrumentals: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      karaokes: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      poems: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      songTodayCount: null,
      folkTodayCount: null,
      instrumentalTodayCount: null,
      karaokeTodayCount: null,
      poemTodayCount: null,
    }
  },
  methods: {
    loadSongs() {
      let query = `query($page: Int) {
        songs(first: 5, page: $page, orderBy: [{column: "id", order: DESC}]) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 5, orderBy: [{column: "song_artist.order", order: ASC}]) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.songs.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.songs) {
              this.songs = data.data.songs;
              this.loadSongTodayCount();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadSongTodayCount() {
      let query = `query {
        songs(first: 1, today: true) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.songs.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.songs && data.data.songs.paginatorInfo) {
              this.songTodayCount = data.data.songs.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadFolks() {
      let query = `query($page: Int) {
        folks(first: 5, page: $page, orderBy: [{column: "id", order: DESC}]) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 5) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.folks.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.folks) {
              this.folks = data.data.folks;
              this.loadFolkTodayCount();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadFolkTodayCount() {
      let query = `query {
        folks(first: 1, today: true) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.folks.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.folks && data.data.folks.paginatorInfo) {
              this.folkTodayCount = data.data.folks.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadInstrumentals() {
      let query = `query($page: Int) {
        instrumentals(first: 5, page: $page, orderBy: [{column: "id", order: DESC}]) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 5) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.instrumentals.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.instrumentals) {
              this.instrumentals = data.data.instrumentals;
              this.loadInstrumentalTodayCount();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadInstrumentalTodayCount() {
      let query = `query {
        instrumentals(first: 1, today: true) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.instrumentals.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.instrumentals && data.data.instrumentals.paginatorInfo) {
              this.instrumentalTodayCount = data.data.instrumentals.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadKaraokes() {
      let query = `query($page: Int) {
        karaokes(first: 5, page: $page, orderBy: [{column: "id", order: DESC}]) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            users(first: 10) {
              data {
                id
                username
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.karaokes.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.karaokes) {
              this.karaokes = data.data.karaokes;
              this.loadKaraokeTodayCount();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadKaraokeTodayCount() {
      let query = `query {
        karaokes(first: 1, today: true) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.karaokes.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.karaokes && data.data.karaokes.paginatorInfo) {
              this.karaokeTodayCount = data.data.karaokes.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadPoems() {
      let query = `query($page: Int) {
        poems(first: 5, page: $page, orderBy: [{column: "id", order: DESC}]) {
          data {
            id
            slug
            title
            created_at
            thumbnail {
              url
            }
            artists(first: 5) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.poems.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.poems) {
              this.poems = data.data.poems;
              this.loadPoemTodayCount();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadPoemTodayCount() {
      let query = `query {
        poems(first: 1, today: true) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.poems.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.poems && data.data.poems.paginatorInfo) {
              this.poemTodayCount = data.data.poems.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadSongs();
    this.loadFolks();
    this.loadInstrumentals();
    this.loadKaraokes();
    this.loadPoems();
  }
}
</script>
