<template>
  <div>
    <table v-if="items.data && items.data.length" class="w-full">
      <tbody>
      <tr :item="item" v-for="item in items.data" :key="'item' + item.id">
        <td class="px-2 py-2 text-emerald-600 font-medium whitespace-nowrap">
          <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full" :class="{'bg-red-600': item.channel === 'vov1', 'bg-green-600': item.channel === 'vov2', 'bg-blue-600': item.channel === 'vov3', 'bg-gray-600': item.channel.indexOf('vovgiaothong') > -1}">{{ item.channel }}</span>
          <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full bg-yellow-600">{{ item.date }}/{{ item.month }}/{{ item.year }}</span>
          <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full bg-gray-600">{{ item.start_time }}-{{ item.end_time }}</span>
        </td>
        <td class="px-2 py-2 text-emerald-600 font-medium flex items-center space-x-2">
          <router-link :to="{name: 'VovProgramDetail', params: {id: item.id, slug: item.slug}}" :title="item.title">
            <h3 class="">
              {{ item.title }}
            </h3>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mt-4">
      <v-pagination
          class="justify-content-end"
          v-if="items.paginatorInfo.lastPage > 1"
          v-model="items.paginatorInfo.currentPage"
          :pages="items.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadVovPrograms"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {timeago} from "../../core/services/utils.service";

export default {
  name: "VovProgramBox",
  components: {},
  data() {
    return {
      items: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadVovPrograms() {
      let query = `query($page: Int) {
        vovPrograms(first: 5, page: $page, where: {AND: [{column: "file_id", operator: GT, value: 0}]}, orderBy: [{column:"id", order: DESC}]) {
          data {
            id
            title
            slug
            channel
            date
            month
            year
            created_at
            start_time
            end_time
          }
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.items.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.vovPrograms) {
              this.items = data.data.vovPrograms;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    timeago(x) {
      return timeago(x);
    }
  },
  mounted() {
    this.loadVovPrograms();
  }
}
</script>
