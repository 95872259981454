<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-4/6 space-y-7">
      <div class="card">
        <div class="p-3 md:flex items-center justify-between">
          <div>
            <h1 class="text-2xl font-semibold">{{ vovProgram.title }}</h1>
            <div class="space-x-1 mt-3">
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full" :class="{'bg-red-600': vovProgram.channel === 'vov1', 'bg-green-600': vovProgram.channel === 'vov2', 'bg-blue-600': vovProgram.channel === 'vov3', 'bg-gray-600': vovProgram.channel.indexOf('vovgiaothong') > -1}">{{ vovProgram.channel }}</span>
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full bg-yellow-600">{{ vovProgram.date }}/{{ vovProgram.month }}/{{ vovProgram.year }}</span>
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 rounded-full bg-gray-600">{{ vovProgram.start_time }}-{{ vovProgram.end_time }}</span>
            </div>
          </div>
        </div>

        <div class="p-4" v-if="vovProgram.content">
          <show-more :show="true" :content="vovProgram.content" :length="200" class="space-y-3"></show-more>
        </div>

        <div class="p-4">
          <div v-if="!isEditing">
            <p v-if="!vovProgram.content">Chương trình chưa được cập nhật nội dung.</p>
            <div class="" v-if="updateContentUsers && updateContentUsers.length">
              <p class="font-semibold">Đóng góp nội dung:</p>
              <div class="flex items-center">
                <router-link :uk-tooltip="'title: ' + user.username" v-for="(user,index) in updateContentUsers"
                            v-bind:class="{'-ml-2': index > 0 }"
                            :key="'updateLyricUsers' + index" :to="{name: 'UserDetail', params: {id: user.id}}"
                            :title="user.username">
                  <img
                      :alt="user.username"
                      :src="user && user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                      class="w-10 h-10 rounded-full border-2 border-white dark:border-gray-900">
                </router-link>
              </div>
            </div>
            <p class="float-right mt-2 mb-2" v-if="canUpdateVovContent">
              <button v-on:click="focusOnEditor"
                      class="comment-btn hover:bg-green-700">
                <i class="uil-pen mr-1"></i>Sửa nội dung
              </button>
            </p>
          </div>

          <div v-if="isEditing">
            <editor id="vovContent" v-model:content="newContent"/>
            <div class="flex justify-content-end">
              <button class="cancel-comment-btn mt-2 mr-2" v-on:click="isEditing = false">
                Hủy
              </button>
              <button class="comment-btn mt-2 hover:bg-green-700" v-on:click="updateVovContent" :disabled="disableContentBtn">Cập
                nhật
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="justify-center flex">
        <div class="flex justify-content-between space-x-7">
          <div class="text-center">
            <div class="flex flex-col items-center" :uk-tooltip="'title: ' + thousandFormat(vovProgram.views) + ' lượt nghe'">
              <span
                  class="mb-2 px-4 py-1 rounded-md border border-gray-300 font-medium text-white hover:text-white hover:bg-green-700 bg-green-600"><i
                  class="uil uil-headphones"></i></span>
              <span>{{ numberFormat(vovProgram.views) }}</span>
            </div>
          </div>
          <div class="text-center">
            <div class="flex flex-col items-center"
                 :uk-tooltip="'title: ' + thousandFormat(vovProgram.downloads) + ' lượt tải'">
              <a class="mb-2 px-4 py-1 rounded-md border border-gray-300 font-medium text-white hover:text-white hover:bg-green-700 bg-green-600"
                 href="javascript:void(0)" v-on:click="download">
                <i class="uil uil-download-alt"></i> Tải về
              </a>
              <span>{{ numberFormat(vovProgram.downloads) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-3 mt-4">
        <comment object-type="vovProgram" :object-id="id" field="id" type="ID"/>
      </div>

      <div class="card p-3 mt-4">
        <h2 class="text-xl font-semibold">Khai phá VOV</h2>
        <vov-program-box/>
      </div>

      <div class="card p-3 mt-4">
        <latest-song-box/>
      </div>
    </div>

    <div class="lg:w-2/6 w-full mb-4">
      <div class="card p-3">
        <latest-comment/>
      </div>

      <div class="card p-3 mt-4">
        <statistic-listen/>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import Comment from "../comment/Comment";
import VovProgramBox from "./VovProgramBox";
import LatestComment from "../comment/LatestComment";
import StatisticListen from "../statistic/StatisticListen";
import LatestSongBox from "../song/LatestSongBox";
import Editor from "../Editor";
import ShowMore from "../ShowMore";

import {makePlayTime, convertLineBreak, changePageTitle, logActivity, numberFormat, thousandFormat, canUpdateLyric} from "../../core/services/utils.service";

export default {
  name: "VovProgramDetail",
  components: {ShowMore, Editor, Comment, VovProgramBox, LatestComment, StatisticListen, LatestSongBox},
  data() {
    return {
      id: null,
      vovProgram: {
        id: "",
        title: "",
        content: "",
        date: "",
        month: "",
        year: "",
        channel: "",
        views: 0,
        downloads: 0,
        start_time: "",
        end_time: ""
      },
      updateContentUsers: [],
      updateContentUserIds: [],
      newContent: "",
      isEditing: false,
      disableLyricBtn: false,
    }
  },
  methods: {
    loadVovProgram() {
      let query = `query($id: ID!) {
        vovProgram(id: $id) {
          title
          content
          date
          month
          year
          channel
          start_time
          end_time
          views
          downloads
          file {
            audio_url
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.vovProgram) {
              this.vovProgram = data.data.vovProgram;
              this.newContent = this.vovProgram.content;
              this.vovProgram.content = makePlayTime(convertLineBreak(this.vovProgram.content));
              changePageTitle("Khai phá VOV: " + this.vovProgram.title + " (" + this.vovProgram.channel + ") - " + this.vovProgram.date + "/" + this.vovProgram.month + "/" + this.vovProgram.year + " " + this.vovProgram.start_time + "-" + this.vovProgram.end_time);
              this.vovProgram.file_type = "audio";
              this.$emit("playPlaylist", this.vovProgram, "song", false, "vov", null);
              logActivity("view", "vovProgram", this.id);
              this.loadUpdateContentUsers();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateVovContent() {
      if (!this.newContent) {
        return;
      }

      this.disableContentBtn = true;

      let query = `mutation($id: ID!, $content: String!) {
        updateVovContent(id: $id, content: $content)
      }`;

      ApiService.graphql(query, {id: this.id, content: this.newContent})
          .then(({data}) => {
            if (data.data && data.data.updateVovContent) {
              this.loadVovProgram();
              this.isEditing = false;
              this.disableContentBtn = false;
            } else {
              alert(data.errors[0].message);
              this.disableContentBtn = false;
            }
          })
          .catch((response) => {
            console.log(response);
            this.disableContentBtn = false;
          });
    },
    loadUpdateContentUsers() {
      let query = `query($object_id: Mixed, $object_type: Mixed) {
        activities(first: 100, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "action", value: "update_vov_content"}, {column: "object_type", value: $object_type}, {column: "object_id", value: $object_id}]}) {
          edges {
            node {
              user {
                id
                username
                avatar {
                  url
                }
              }
            }
            cursor
          }
        }
      }
      `;

      ApiService.graphql(query, {object_id: this.id, object_type: "vovProgram"})
          .then(({data}) => {
            if (data.data && data.data.activities && data.data.activities.edges && data.data.activities.edges.length) {
              this.updateContentUsers = [];
              this.updateContentUserIds = [];
              data.data.activities.edges.map((item) => {
                if (!this.updateContentUserIds.includes(item.node.user.id)) {
                  this.updateContentUsers.push(item.node.user);
                  this.updateContentUserIds.push(item.node.user.id);
                }
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    focusOnEditor() {
      this.isEditing = true;
      
      setTimeout(function() {
        window.$("#vovContent .ck-editor__editable").focus();
      }, 100);
    },
    download() {
      logActivity("download", "vovProgram", this.id);
      let data = {
        link: this.vovProgram.file.audio_url,
        composers: this.vovProgram.channel,
        artists: this.vovProgram.date + "." + this.vovProgram.month + "." + this.vovProgram.year + " " + this.vovProgram.start_time.replace(":", "h") + "-" + this.vovProgram.end_time.replace(":", "h"),
        title: this.vovProgram.title
      };

      let token = window.btoa(encodeURIComponent(JSON.stringify(data)));
      let url = process.env.VUE_APP_MP3_API_URL + "/download?token=" + token;
      window.open(url, '_blank').focus();
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadVovProgram();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    window.scrollTo(0, 0);
    next();
    this.loadVovProgram();
  },
  computed: {
    canUpdateVovContent() {
      return canUpdateLyric();
    }
  }
}
</script>
